import './vendor';
import '@babel/polyfill';

import * as Ladda from 'ladda';

import {
  disableAutoCompleteOnForms,
  injectGitHashIntoDOM,
  markFieldErrors,
  renderStandaloneMediaCards,
  supportNonGETMethodsOnLinks,
} from './bootstrap';

require('./app.scss');

document.addEventListener('DOMContentLoaded', () => {
  injectGitHashIntoDOM();
  markFieldErrors();
  disableAutoCompleteOnForms();
  supportNonGETMethodsOnLinks();
  renderStandaloneMediaCards();

  Ladda.bind('button[type=submit]:not(.js-no-ladda)');
});
