//
// Add a <code> tag to every page with the git hash of the current app version.
import MediaCard from 'components/MediaCard.vue';
import Vue from 'vue';

import { els, el } from './lib/helpers';

//
export const injectGitHashIntoDOM = () => {
  if (!process.env.VERSION) {
    return;
  }

  const $version = document.createElement('code');

  $version.id = 'app-version';
  $version.textContent = process.env.VERSION;

  el('body').appendChild($version);
};

export const markFieldErrors = () => {
  const $errorAlert = el('.alert-danger');

  if (!$errorAlert) {
    return;
  }

  const errors = ($errorAlert.dataset.fields || '').split(/,\s+/);

  if (errors.length === 0) {
    return;
  }

  errors
    .filter(f => !!f.length)
    .forEach(field =>
      $(`[name="${field}"], [name="${field}[]"]`)
        .closest('.form-group')
        .addClass('has-error')
    );
};

export const supportNonGETMethodsOnLinks = () =>
  document.body.addEventListener('click', event => {
    if (!(event.target instanceof Element)) {
      return;
    }

    const element = event.target.closest('[data-method]');

    if (!(element instanceof HTMLElement)) {
      return;
    }

    if (!element.dataset.method) {
      return;
    }

    event.preventDefault();

    const confirmation = element.dataset.confirm;

    if (confirmation && !confirm(confirmation)) {
      return;
    }

    const form = document.createElement('form');

    form.setAttribute('hidden', 'hidden');
    form.setAttribute('method', 'POST');
    form.setAttribute('action', element.getAttribute('href') || '');

    const csrfInput = document.createElement('input');
    const csrfTokenMeta = el('meta[name="csrf-token"]');

    if (!csrfTokenMeta) {
      throw new Error(
        'No csrf-token meta tag is present; could not submit link form.'
      );
    }

    csrfInput.setAttribute('type', 'hidden');
    csrfInput.setAttribute('name', '_token');
    csrfInput.setAttribute(
      'value',
      csrfTokenMeta.getAttribute('content') || ''
    );

    const methodInput = document.createElement('input');

    methodInput.setAttribute('type', 'hidden');
    methodInput.setAttribute('name', '_method');
    methodInput.setAttribute(
      'value',
      (element.dataset.method || '').toUpperCase()
    );

    form.appendChild(csrfInput);
    form.appendChild(methodInput);

    document.body.appendChild(form);

    form.submit();
  });

export const disableAutoCompleteOnForms = () => {
  els('form:not([autocomplete]), input:not([autocomplete])').forEach(element =>
    element.setAttribute('autocomplete', 'off')
  );
};

/**
 * Most pages don't work much with Vue.js at all. There are a handful though that
 * want to render a MediaCard component. These instances are expected to be wrapped
 * in a .js-media-card div which this method will attach a new Vue.js instance
 * for the media card rendering.
 */
export const renderStandaloneMediaCards = () =>
  els('.js-media-card').forEach(
    el =>
      new Vue({
        el,
        components: {
          MediaCard,
        },
      })
  );
